import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo' 

const DisclaimerPage = ({ data }) => (  
  <Layout> 
    <Seo
      title={"FTC Disclaimer and Disclosure:"}
      description={
        'FTC Disclaimer and Disclosure:'
      }
    />
<React.Fragment>
<h2 class="section"><b>
FTC Disclaimer and Disclosure:
   </b></h2>
 
    <p>
We receive compensation from some of the hosting companies listed on this website. This means we earn a commission every time you make a purchase by following our link. This doesn’t cost you anything additional. These small commissions help us to cover the expenses required to run the website, so thank you!
</p><p>
If you find our website useful, please support by purchasing via our affiliate links. It never cost you more – instead in many cases, our special deals are going to save you money. We only try to present the best web hosting companies in our site.
</p><p>
We would also like you to know that we are never influenced by anyone to write a good review and we believe in honesty. We work very hard to produce all the valuable web hosting articles, tutorials, web hosting guides and web hosting reviews.
</p><p>
Disclaimer and Terms of Use:
Before using findbestwebhosting.com, please read Terms of Use carefully . By using this web site, you indicate that you accept these terms of services.
        </p>

 
    </React.Fragment>
  </Layout>
)

export default DisclaimerPage
